import React, { useEffect, useState } from 'react'

import SEO from '../components/seo'
import Layout from '../components/layout'
import useWindowSize from '../hooks/useWindowSize'
import Pattern from '../images/tuya-pattern-menu.inline.svg'

// Styles
import '../styles/pages/reservation.scss'

const HeroSection = () => (
  <section className="bg-grey-200 hero-section flex items-end px-6 pb-7 md:pb-16 md:px-20 lg:px-32 ">
    <div className="2xl:max-w-screen-xl 2xl:mx-auto w-full">
      <h1 className="text-tuya-dark ">Reservation</h1>
    </div>
  </section>
)

const ReservationPage = ({ location }) => {
  const windowSize = useWindowSize()
  const [iframeSize, setIframeSize] = useState({ width: '1000px', height: '800px' })

  useEffect(() => {
    if (windowSize.width !== undefined && windowSize.width <= 1000) {
      setIframeSize({ width: '100%', height: windowSize.height + 'px' })
    }
  }, [windowSize])

  return (
    <Layout className="reservation-page" pathname={location.pathname} hasLightBg simpleHeader>
      <SEO title="Reservation" />
      <HeroSection />
      <section className="main-section">
        <div className="wrapper text-center pt-32">
          {/* Under construction */}
          <iframe
            title="Tuya Restaurant"
            style={{ width: iframeSize.width, height: iframeSize.height, margin: 'auto' }}
            src="https://eatapp.co/reserve/tuya-restaurant-062714?source=iframe"
          />
        </div>
      </section>
    </Layout>
  )
}

export default ReservationPage
